import * as React from "react";

function SvgDryer(props) {
  return (
    <svg viewBox="0 0 10.583 10.583" {...props}>
      <g fill="none" stroke="#000">
        <ellipse
          cx={5.32}
          cy={5.282}
          rx={4.496}
          ry={4.549}
          strokeWidth={0.418}
        />
        <path strokeWidth={0.265} d="M.14.158h10.349v10.31H.14z" />
      </g>
    </svg>
  );
}

export default SvgDryer;
