import * as React from "react";

function SvgTutorialTagSchool(props) {
  return (
    <svg viewBox="0 0 52.917 52.917" {...props}>
      <path
        fill="none"
        stroke="#acbac8"
        strokeWidth={0.882}
        d="M.383.243h52.323v52.806H.383z"
      />
      <path
        d="M-24.22-59.605c2.958.417 26.074 31.135 25.657 34.092C1.019-22.556-29.698.561-32.656.143c-2.957-.418-26.073-31.135-25.656-34.092.418-2.958 31.135-26.074 34.093-25.656z"
        transform="matrix(-.76247 -.57207 .57502 -.75855 22.464 -8.275)"
        fill="none"
        stroke="#6b6b6b"
        strokeWidth={0.327}
        strokeLinecap="round"
        strokeLinejoin="round"
        paintOrder="fill markers stroke"
      />
      <path
        d="M.782 49.716a8.549 8.549 0 004.995-2.175"
        fill="none"
        stroke="#000"
        strokeWidth={0.234}
      />
      <path
        d="M52.187 50.148a3.777 3.777 0 00-3.915-1.193"
        fill="none"
        stroke="#000"
        strokeWidth={0.154}
      />
      <path
        d="M.877 51.224v-1.358l.544-.084c1.273-.194 2.801-.83 3.782-1.571.267-.201.486-.36.487-.353.092.655.362 2.007.47 2.352.249.805.656 1.055 2.167 1.333 6.155 1.132 26.726 1.305 35.547.3 2.065-.236 3.275-.522 3.635-.859.227-.212.431-.743.6-1.56.071-.339.132-.56.135-.492.005.103.043.118.205.086a8.53 8.53 0 01.618-.081c.875-.087 2.022.322 2.718.968.198.184.386.319.417.3.032-.02.058.507.058 1.17v1.207H.878z"
        fill="#cee6f4"
        fillRule="evenodd"
        paintOrder="fill markers stroke"
      />
      <path
        fill="none"
        stroke="#000"
        strokeWidth={0.201}
        strokeLinecap="round"
        strokeLinejoin="round"
        paintOrder="fill markers stroke"
        d="M36.314 19.658h7.127v6.309h-7.127z"
      />
      <ellipse
        cx={39.866}
        cy={22.812}
        rx={3.358}
        ry={3.165}
        fill="none"
        stroke="#000"
        strokeWidth={0.31}
        strokeLinecap="round"
        strokeLinejoin="round"
        paintOrder="fill markers stroke"
      />
      <ellipse
        cx={38.575}
        cy={22.926}
        rx={0.632}
        ry={0.499}
        fill="#0d1419"
        fillRule="evenodd"
        paintOrder="fill markers stroke"
      />
      <ellipse
        cx={40.798}
        cy={22.92}
        rx={0.632}
        ry={0.499}
        fill="#0d1419"
        fillRule="evenodd"
        paintOrder="fill markers stroke"
      />
      <path
        d="M19.813 25.784l2.789-5.976 3.051 5.979z"
        fill="none"
        stroke="#000"
        strokeWidth={0.248}
      />
      <path
        d="M19.912 21.45l5.948 2.853"
        fill="none"
        stroke="#000"
        strokeWidth={0.182}
      />
      <path
        d="M19.727 24.048l6.307-2.848"
        fill="none"
        stroke="#000"
        strokeWidth={0.193}
      />
      <path
        d="M10.826 20.322l.922 5.233 4.425.06 1.77-5.18"
        fill="none"
        stroke="#000"
        strokeWidth={0.265}
      />
      <path
        d="M11.026 21.737c.587-.346 1.173-.693 1.622-.705.45-.012.744.294 1.16.347.416.053.944-.155 1.437-.159.492-.004.95.197 1.283.322.333.126.541.178.749.23"
        fill="none"
        stroke="#000"
        strokeWidth={0.265}
      />
      <ellipse
        cx={14.034}
        cy={23.269}
        rx={0.632}
        ry={0.499}
        fill="#0d1419"
        fillRule="evenodd"
        paintOrder="fill markers stroke"
      />
      <path
        d="M27.316 25.504l6.89-.023.2-5.535-6.831.189"
        fill="none"
        stroke="#000"
        strokeWidth={0.265}
      />
      <path
        d="M27.316 25.504c1.093-1 2.185-2 3.293-2.492s2.23-.475 3.352-.458"
        fill="none"
        stroke="#000"
        strokeWidth={0.265}
      />
      <ellipse
        cx={31.533}
        cy={24.242}
        rx={0.632}
        ry={0.499}
        fill="#0d1419"
        fillRule="evenodd"
        paintOrder="fill markers stroke"
      />
      <path
        d="M7.27 37.63c.902-.775 1.803-1.55 2.58-1.57.778-.02 1.441.725 2.252.723.81-.002 1.773-.746 2.562-.775.788-.03 1.402.654 2.204.607.802-.046 1.791-.823 2.59-.836.798-.012 1.43.77 2.213.75.783-.019 1.73-.823 2.598-.828.87-.006 1.662.787 2.529.793.866.007 1.808-.774 2.57-.787.76-.013 1.342.741 2.078.703.735-.039 1.626-.87 2.386-.953.76-.084 1.391.58 2.136.552.745-.028 1.605-.748 2.316-.68.71.07 1.271.925 1.919.915.647-.01 1.38-.887 2.04-.97.661-.084 1.249.626 1.923.653.673.027 1.433-.628 1.931-.653.499-.024.737.583.975 1.19M7.267 41.482c.901-.775 1.802-1.55 2.58-1.57.777-.02 1.44.725 2.251.723.81-.002 1.773-.746 2.562-.775.788-.03 1.403.654 2.204.607.802-.046 1.791-.824 2.59-.836.799-.012 1.43.77 2.213.75.783-.019 1.73-.823 2.599-.829.87-.005 1.661.788 2.528.794.867.007 1.808-.774 2.57-.787.761-.013 1.342.74 2.078.702.736-.038 1.626-.869 2.387-.952.76-.084 1.39.58 2.136.552.745-.028 1.604-.748 2.315-.68.71.07 1.272.925 1.919.915.647-.01 1.38-.887 2.04-.97.661-.084 1.25.626 1.923.653.673.027 1.433-.628 1.932-.653.498-.024.736.583.974 1.19M6.999 34.364c.9-.775 1.802-1.55 2.58-1.57.777-.02 1.44.725 2.251.723.81-.002 1.773-.746 2.562-.776.788-.03 1.403.655 2.204.608.802-.047 1.791-.824 2.59-.836.799-.013 1.43.77 2.213.75.783-.019 1.73-.823 2.599-.829.87-.005 1.661.788 2.528.794.867.006 1.808-.774 2.57-.787.761-.013 1.342.74 2.078.702.736-.038 1.626-.87 2.387-.952.76-.084 1.39.58 2.136.552.744-.028 1.604-.748 2.315-.68.71.069 1.272.925 1.919.915.647-.01 1.38-.888 2.04-.971.661-.083 1.25.626 1.923.653.673.028 1.433-.628 1.932-.652.498-.024.736.583.974 1.19"
        fill="none"
        stroke="#000"
        strokeWidth={0.265}
      />
      <path
        d="M10.169 26.1l7.773-5.664M10.11 20.164l7.996 6.318"
        fill="none"
        stroke="#fb4a4a"
        strokeWidth={0.865}
      />
      <ellipse
        cx={22.597}
        cy={23.035}
        rx={3.422}
        ry={3.456}
        fill="none"
        stroke="#3ad700"
        strokeWidth={0.623}
        strokeLinecap="round"
        strokeLinejoin="round"
        paintOrder="fill markers stroke"
      />
      <ellipse
        cx={31.21}
        cy={22.799}
        rx={3.422}
        ry={3.456}
        fill="none"
        stroke="#3ad700"
        strokeWidth={0.623}
        strokeLinecap="round"
        strokeLinejoin="round"
        paintOrder="fill markers stroke"
      />
      <g>
        <path
          d="M35.667 25.323l7.774-5.665M35.608 19.386l7.997 6.319"
          fill="none"
          stroke="#fb4a4a"
          strokeWidth={0.865}
        />
      </g>
    </svg>
  );
}

export default SvgTutorialTagSchool;
