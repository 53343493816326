import * as React from "react";

function SvgWasher(props) {
  return (
    <svg viewBox="0 0 10.583 10.583" {...props}>
      <g fill="none" stroke="#000" strokeWidth={0.265}>
        <path d="M.025 1.69l2.206 8.833 5.56-.046 2.766-8.754" />
        <path d="M.442 3.26c.383-.328.766-.656 1.096-.67.33-.015.607.283.89.285.283.003.572-.289.881-.314.31-.024.643.22.966.213.322-.008.636-.268.943-.266.306.002.606.266.923.26.317-.006.651-.282.945-.284.294-.002.548.27.828.248.28-.023.588-.34.959-.328.37.012.806.352 1.24.692" />
      </g>
    </svg>
  );
}

export default SvgWasher;
