import * as React from "react";

function SvgDryerHot(props) {
  return (
    <svg viewBox="0 0 10.583 10.583" {...props}>
      <ellipse
        cx={5.32}
        cy={5.282}
        rx={4.496}
        ry={4.549}
        fill="none"
        stroke="#000"
        strokeWidth={0.418}
      />
      <path
        fill="none"
        strokeWidth={0.265}
        stroke="#000"
        d="M.14.158h10.349v10.31H.14z"
      />
      <ellipse ry={0.452} rx={0.435} cy={5.194} cx={4.011} fillRule="evenodd" />
      <ellipse cx={5.289} cy={5.167} rx={0.435} ry={0.452} fillRule="evenodd" />
      <ellipse ry={0.452} rx={0.435} cy={5.154} cx={6.59} fillRule="evenodd" />
    </svg>
  );
}

export default SvgDryerHot;
