import { createContext, useContext, useState } from "react";

const UpdateContext = createContext();

export const UpdateProvider = ({ children }) => {
  const [updateAvailable, setUpdateAvailable] = useState(false);

  async function updateServiceWorker() {
    const sw = await navigator.serviceWorker.getRegistration();
    sw.waiting && sw.waiting.postMessage({ type: "SKIP_WAITING" });
    setUpdateAvailable(false);
  }

  if ("serviceWorker" in navigator && process.env.NODE_ENV === "production") {
    window.addEventListener("load", async () => {
      const registration = await navigator.serviceWorker.register(
        `${process.env.PUBLIC_URL}/suds-service-worker.js`
      );
      // ensure the case when the updatefound event was missed is also handled
      // by re-invoking the prompt when there's a waiting Service Worker
      if (registration.waiting) {
        setUpdateAvailable(true);
      }
      registration.addEventListener("updatefound", () => {
        if (registration.installing) {
          registration.installing.addEventListener("statechange", () => {
            if (registration.waiting) {
              // if there's an existing controller (previous Service Worker), show the prompt
              // otherwise it's the first install, nothing to do
              if (navigator.serviceWorker.controller) {
                setUpdateAvailable(true);
              }
            }
          });
        }
      });

      let refreshing = false;
      // detect controller change and refresh the page
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (!refreshing) {
          window.location.reload();
          refreshing = true;
        }
      });
    });
  }

  return (
    <UpdateContext.Provider value={{ updateAvailable, updateServiceWorker }}>
      {children}
    </UpdateContext.Provider>
  );
};

export const useUpdateAvailable = () => {
  return useContext(UpdateContext);
};
