import * as React from "react";

function SvgFlatdry(props) {
  return (
    <svg viewBox="0 0 10.583 10.583" {...props}>
      <g fill="none" strokeWidth={0.265} stroke="#000">
        <path d="M.153.182h10.291v10.261H.153zM1.526 5.32l7.742.021" />
      </g>
    </svg>
  );
}

export default SvgFlatdry;
