import { useEffect, useState } from "react";
import { loadModel } from "../assets/js/yolo";

export const useModel = () => {
  const [model, setModel] = useState(null);

  useEffect(() => {
    if (model === null) {
      loadModel().then((suds) => {
        setModel(suds);
        // warm up model
      });
    }
    console.log("Creating model..");
    console.log("model", model);

    return () => {
      model && model.dispose();
    };
  }, [model]);

  return model;
};

export default useModel;
