import React from "react";

function MenuButton(props) {
  return (
    <svg viewBox="0 0 100 80" width="40" height="40" {...props}>
      <rect width="100" height="10" fill="#FFFFFF"></rect>
      <rect y="30" width="100" height="10" fill="#FFFFFF"></rect>
      <rect y="60" width="100" height="10" fill="#FFFFFF"></rect>
    </svg>
  );
}

export default MenuButton;
