import * as React from "react";

function SvgStopwatch(props) {
  return (
    <svg viewBox="0 0 850 981" {...props}>
      <path d="M308 3.3c-5 2.6-10.3 9.7-12.2 16-.6 2.3-1.2 7.1-1.2 10.7 0 3.6.6 8.4 1.2 10.7 1.9 6.3 7.2 13.4 12.2 16 4.1 2.2 5.4 2.3 24.7 2.3H353v79.8l-6.7 1.2c-52.9 9.6-109.9 32.8-155.4 63.2-25.2 16.9-41.8 30.4-63.8 52.1-60.7 60-100.8 133.9-118 217.8-9.9 48.1-10.6 105.2-2 156.4 6.3 37.9 19.9 79.4 37.5 114.7 10.1 20.2 15 28.8 26.9 46.8C113.2 853.7 169 903 236 936.4c29.6 14.7 55.5 24.2 87.5 32.1 35.6 8.8 62.5 12 101.5 12 28.1 0 42.5-1 65.5-4.6C608 957.6 712.6 890.5 778.4 791c12.2-18.3 16.8-26.4 26.8-46.5 39.6-79.3 52.8-170.2 37.7-260.5-4.8-28.6-15.7-65.4-27.9-94-43.6-102.5-128.7-185.6-232.3-227-23.6-9.4-53.3-18.2-76.4-22.5l-9.3-1.8V59h20c14.7 0 20.9-.4 23.3-1.4 4.5-1.8 11.2-9.7 13.1-15.3 2.1-6 2.1-18.6 0-24.6-1.9-5.6-8.6-13.5-13.1-15.3-2.7-1.2-23-1.4-115.6-1.4H312.3L308 3.3zm142.8 202.3c42.2 2.4 91 15.7 131.3 36 100.8 50.5 171.7 146.3 190.2 256.9 6.5 38.8 6.2 83.3-.9 122.5-12.1 66.7-45.4 130.9-93.9 180.9-56.7 58.5-129.2 94.4-211.5 104.8-16.5 2.1-62.3 2.4-77.5.5-45.6-5.6-80.4-15.7-119-34.7-68.7-33.7-125-89.6-159.4-158.4-20.3-40.4-31.9-81.6-36.2-128.4-1.5-16.4-.6-58 1.6-73.6 7.8-56.2 25.6-103.6 56.1-149.6 56.2-85.1 147.3-141.9 248.4-155.1 22.9-2.9 42.4-3.5 70.8-1.8z" />
      <path d="M409 256.1c-97.8 4.7-187.9 58-240 142-38.6 62.1-53 135.8-40.9 208.9 7.4 44.6 26.1 89.3 52.8 126C289 881.8 502 900.7 634.2 773.2c77.2-74.5 108.8-185.1 82.7-290-18.9-76.3-69.1-144-136.8-184.6-52-31.2-109.6-45.6-171.1-42.5zm16 173.8v112.8l-108.7 41.8c-59.9 23-110.9 42.6-113.5 43.6l-4.7 1.8-2-8.7c-4.5-19.4-5.6-30.6-5.6-56.7 0-28.2 1.3-39.2 7.2-63 8.9-35.5 26.5-70.9 49.3-99.1 14.3-17.6 37.8-38.4 57.2-50.7 32.9-20.8 75.1-33.6 113.6-34.5l7.2-.2v112.9zM161 119.7c-40 2.6-73.8 30.3-85.2 69.9-2 6.8-2.3 10.2-2.3 24.4 0 17.2.9 23 5.6 34.6l2 5 19.7-19.6c10.8-10.8 23.8-23 28.7-27.2 27.1-22.6 54.2-40.7 85.4-57.1l14.4-7.5-6.4-4.7c-16.8-12.3-40.6-19.2-61.9-17.8z" />
    </svg>
  );
}

export default SvgStopwatch;
