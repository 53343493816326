import * as React from "react";

function SvgIronMed(props) {
  return (
    <svg viewBox="0 0 10.583 10.583" {...props}>
      <path
        d="M2.028 1.587L8.2 1.52l2.191 6.913-10.328.084"
        fill="none"
        stroke="#000"
        strokeWidth={0.263}
      />
      <path
        d="M.063 8.518C.637 7.242 1.21 5.967 2.712 5.3c1.5-.667 3.93-.726 6.358-.784"
        fill="none"
        stroke="#000"
        strokeWidth={0.262}
      />
      <ellipse ry={0.432} rx={0.418} cy={6.649} cx={4.456} fillRule="evenodd" />
      <ellipse cx={5.972} cy={6.648} rx={0.418} ry={0.432} fillRule="evenodd" />
    </svg>
  );
}

export default SvgIronMed;
