import * as React from "react";

function SvgDripdry(props) {
  return (
    <svg viewBox="0 0 10.583 10.583" {...props}>
      <g fill="none" strokeWidth={0.265} stroke="#000">
        <path d="M.153.182h10.291v10.261H.153zM2.248 1.127l.028 8.735M5.02 1.073l.028 8.735M7.825 1.142l.027 8.735" />
      </g>
    </svg>
  );
}

export default SvgDripdry;
