import React from "react";
import { useMobile } from "../../hooks";

import { useTranslation } from "react-i18next";

const Footer = (props) => {
  const isMobile = useMobile();
  const { t } = useTranslation();
  const copyrightYear = new Date().getFullYear();
  return isMobile ? (
    <div className="w-full p-4 bg-gray text-ivory-light flex-none relative">
      <span className="absolute bottom-0 left-0 ml-2">
        <small>
          <a href="https://www.vecteezy.com/free-vector/laundry-logo">
            Laundry Logo Vectors by Vecteezy
          </a>
        </small>
      </span>
      <span className="absolute bottom-0 right-0 mr-2">
        <small className="pl-2">&copy; {copyrightYear}</small>
      </span>
    </div>
  ) : (
    <div className="w-full h-10 bg-gray text-ivory-light flex-none">
      <div className="flex flex-row items-center h-full gap-6 ml-4 relative">
        <a href="/about">{t("navigation.aboutUs")}</a>
        <a href="/privacy">{t("navigation.privacy")}</a>
        <a href="/terms_and_conditions">{t("navigation.terms")}</a>
        <span className="absolute bottom-0 right-0 mr-2 flex flex-row items-center h-full gap-6 ml-4">
          <small>
            <a href="https://www.vecteezy.com/free-vector/laundry-logo">
              Laundry Logo Vectors by Vecteezy
            </a>
          </small>
          <small className="pl-2">&copy; {`Copyright ${copyrightYear}, Bryan Robitaille`}</small>
        </span>
      </div>
    </div>
  );
};

export default Footer;
