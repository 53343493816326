import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCode, faXmarkCircle } from "@fortawesome/free-regular-svg-icons";

const Notification = ({ title, messageText, buttonText, onClick }) => {
  const [show, setShow] = useState(true);
  const [working, setWorking] = useState(false);

  const actionButton = () => {
    setWorking(true);
    onClick();
  };

  if (show) {
    return (
      <div className="bg-coffee-light flex-none">
        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              <span className="flex rounded-lg ">
                <FontAwesomeIcon icon={faFileCode} className="h-8" />
              </span>
              <p className="ml-3 md:ml-6 font-medium text-ivory-light truncate">
                <span className=" md:inline">
                  <p>{title}</p>
                  <p className="hidden sm:block">{messageText}</p>
                </span>
              </p>
            </div>
            <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
              {buttonText && !working ? (
                <button
                  onClick={actionButton}
                  className="bg-blue hover:bg-blue-light flex items-center justify-center px-4 py-2 font-bold border border-transparent rounded-md shadow-sm text-sm text-ivory-light w-full "
                >
                  {buttonText}
                </button>
              ) : buttonText && working ? (
                <div className=" flex items-center justify-center ">
                  <div className="w-10 h-10 border-t-4 border-b-4 border-blue rounded-full animate-spin"></div>
                </div>
              ) : null}
            </div>
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
              <button
                type="button"
                className="-mr-1 flex p-2 rounded-md hover:bg-coffee  sm:-mr-2"
                onClick={() => setShow(false)}
              >
                <span className="sr-only">Dismiss</span>
                <FontAwesomeIcon
                  icon={faXmarkCircle}
                  className="h-6 w-6 text-ivory-light"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default Notification;
