import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./escapeHatch";
import Spinner from "./components/Loader/Spinner";
import { UpdateProvider } from "./hooks/useUpdate";
import "./assets/css/Suds.css";
import App from "./App";
import "./i18n";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <Suspense
    fallback={
      <div className="main">
        <div className="flex justify-center items-center h-screen">
          <Spinner />
        </div>
      </div>
    }
  >
    <UpdateProvider>
      <App />
    </UpdateProvider>
  </Suspense>
);
