import * as React from "react";

function SvgWasherHand(props) {
  return (
    <svg viewBox="0 0 10.583 10.583" {...props}>
      <path
        d="M.025 1.69l2.206 8.833 5.56-.046 2.766-8.754"
        fill="none"
        stroke="#000"
        strokeWidth={0.265}
      />
      <path
        d="M.442 3.26c.383-.328.766-.656 1.096-.67.33-.015.607.283.89.285.283.003.572-.289.881-.314.31-.024.643.22.966.213.322-.008.636-.268.943-.266.306.002.606.266.923.26.317-.006.651-.282.945-.284.294-.002.548.27.828.248.28-.023.588-.34.959-.328.37.012.806.352 1.24.692"
        fill="none"
        stroke="#000"
        strokeWidth={0.265}
      />
      <path d="M5.599 6.993c-.152-.099-.207-.331-.11-.515l.936-1.783a.163.163 0 00-.05-.213c-.061-.04-.155-.017-.193.056L4.8 7.17c-.097.184-.306.247-.458.149-.152-.098-.207-.33-.11-.515l1.084-2.065a.163.163 0 00-.05-.214c-.061-.039-.154-.017-.193.057L3.846 6.917c-.096.184-.306.247-.458.149-.152-.098-.207-.33-.11-.515l1.375-2.619a.163.163 0 00-.05-.213c-.072-.046-.155-.017-.193.056L3.325 5.841c-.097.184-.306.247-.458.149-.153-.098-.207-.33-.11-.515l1.866-3.553-.576.14c-.539.146-.927.11-1.029-.318-.059-.3.356-.445.807-.631C4.26.932 5.55.233 6.348.188c0 0 .32-.041.645.168l.771.497c.62.398.733 1.294.261 2.191L6.037 6.831c-.087.19-.286.26-.438.162z" />
    </svg>
  );
}

export default SvgWasherHand;
