import * as React from "react";

function SvgWasherMed(props) {
  return (
    <svg viewBox="0 0 10.583 10.583" {...props}>
      <path
        d="M.025 1.69l2.206 8.833 5.56-.046 2.766-8.754"
        fill="none"
        stroke="#000"
        strokeWidth={0.265}
      />
      <path
        d="M.442 3.26c.383-.328.766-.656 1.096-.67.33-.015.607.283.89.285.283.003.572-.289.881-.314.31-.024.643.22.966.213.322-.008.636-.268.943-.266.306.002.606.266.923.26.317-.006.651-.282.945-.284.294-.002.548.27.828.248.28-.023.588-.34.959-.328.37.012.806.352 1.24.692"
        fill="none"
        stroke="#000"
        strokeWidth={0.265}
      />
      <ellipse ry={0.428} rx={0.463} cy={6.521} cx={4.094} fillRule="evenodd" />
      <ellipse cx={5.72} cy={6.525} rx={0.463} ry={0.428} fillRule="evenodd" />
    </svg>
  );
}

export default SvgWasherMed;
