import * as React from "react";

function SvgBleachNcl(props) {
  return (
    <svg viewBox="0 0 10.583 10.583" {...props}>
      <g fill="none" stroke="#000" strokeWidth={0.265}>
        <path d="M.238 10.475L5.194.298l5.174 10.137zM2.51 10.53l3.838-8.013M4.346 10.574c.137-.278 2.868-6.297 2.868-6.297M6.502 10.488L8.25 6.29" />
      </g>
    </svg>
  );
}

export default SvgBleachNcl;
