import { useState, useEffect } from "react";

const checkIfMobile = () => {
  const { innerWidth: width } = window;
  if (width < 768) {
    return true;
  }
  return false;
};

export const useMobile = () => {
  const [isMobile, setIsMobile] = useState(() => checkIfMobile());

  useEffect(() => {
    function handleResize() {
      setIsMobile(() => checkIfMobile());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};

export default useMobile;
