import * as React from "react";

function SvgBleach(props) {
  return (
    <svg viewBox="0 0 10.583 10.583" {...props}>
      <path
        d="M.238 10.475L5.194.298l5.174 10.137z"
        fill="none"
        stroke="#000"
        strokeWidth={0.265}
      />
    </svg>
  );
}

export default SvgBleach;
