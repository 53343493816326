import React from "react";

const Spinner = (props) => {
  return (
    <div className="flex items-center justify-center">
      <div className="w-40 h-40 border-t-4 border-b-4 border-blue rounded-full animate-spin"></div>
    </div>
  );
};

export default Spinner;
