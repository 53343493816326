import { map, yielding } from "js-coroutines";
const washClasses = [
  0,
  "hand_wash",
  "no_machine_wash",
  "machine_wash",
  "machine_wash_cold",
  "machine_wash_warm",
  "machine_wash_hot",
];

const bleachClasses = [1, "bleach", "bleach_non_chlorine", "no_bleach"];

const dryerClasses = [2, "dryer", "dryer_low", "dryer_warm", "dryer_hot", "no_dryer"];

const ironClasses = [3, "iron", "iron_low", "iron_warm", "iron_hot", "no_iron"];

const dryClasses = [4, "line_dry", "flat_dry", "drip_dry"];

const dryCleanClasses = [5, "no_dry_clean"];

const cycleClasses = [6, "perm_press", "delicate"];

function getClosest(cycle, washDry) {
  let closest = washDry
    .map((symbol) => {
      if (
        cycle.bottom > symbol.bottom &&
        cycle.left > symbol.left - 0.2 * symbol.width &&
        cycle.right < symbol.right + 0.2 * symbol.width
      ) {
        return { symbol, cycle };
      }
      return null;
    })
    .filter((value) => value !== null);

  return closest[0];
}

function getHighestScore(symbols) {
  if (symbols.length) {
    let sorted = symbols.sort((a, b) => {
      return b.score - a.score;
    });
    return sorted[0];
  }
  return [];
}

async function sortDetections(detections) {
  const classes = [
    washClasses,
    bleachClasses,
    dryerClasses,
    ironClasses,
    dryClasses,
    dryCleanClasses,
    cycleClasses,
  ];

  const classified = classes.map((classType) => {
    return detections
      .map((detection) => {
        if (classType.includes(detection.label)) {
          return detection;
        }
        return null;
      })
      .filter((value) => value !== null);
  });

  var cycles = classified[6];
  if (cycles.length && (classified[0].length || classified[2].length)) {
    let washer = cycles.map((cycle) => {
      return getClosest(cycle, classified[0]);
    });

    let dryer = cycles.map((cycle) => {
      return getClosest(cycle, classified[2]);
    });

    // Remove cycles element before getting highest scores
    classified.pop();

    return await Promise.all([washer, dryer]).then(([washerResults, dryerResults]) => {
      let newDetections = classified.map((symbol) => {
        return getHighestScore(symbol);
      });
      if (washerResults[0]) {
        newDetections[0] = [newDetections[0], washerResults[0].cycle];
      } else {
        newDetections[0] = [newDetections[0], {}];
      }
      if (dryerResults[0]) {
        newDetections[2] = [newDetections[2], dryerResults[0].cycle];
      } else {
        newDetections[2] = [newDetections[2], {}];
      }
      return newDetections;
    });
  } else {
    classified.pop();
    return await classified.map((symbol, i) => {
      if (i === 0 || i === 2) {
        return [getHighestScore(symbol), {}];
      } else {
        return getHighestScore(symbol);
      }
    });
  }
}

// Take in existing detected and compare against incoming detections
// Return new detected array
function* compareDetections(detected, features) {
  var changed = false;

  const newDetections = yield sortDetections(features);
  // detected will already be of shape [[{}, {}], {}, [{}, {}], {}, {}, {}]

  const newlyDetected = yield* map(
    newDetections,
    yielding((symbol, i) => {
      if (Array.isArray(symbol) && symbol.length >= 1) {
        let tempStore = symbol.map((item, x) => {
          if (detected[i][x].hasOwnProperty("score")) {
            if (item.hasOwnProperty("score") && item.score > detected[i][x].score) {
              changed = true;
              return item;
            } else {
              let tempSymbol = detected[i][x];
              tempSymbol.score = tempSymbol.score - 0.03;
              return tempSymbol;
            }
          } else {
            if (item.hasOwnProperty("score")) {
              changed = true;
              return item;
            }
            return detected[i][x];
          }
        });

        return tempStore;
      }

      if (detected[i].hasOwnProperty("score")) {
        if (symbol.hasOwnProperty("score") && symbol.score > detected[i].score) {
          changed = true;
          return symbol;
        } else {
          return detected[i];
        }
      } else {
        if (symbol.hasOwnProperty("score")) {
          changed = true;
          return symbol;
        }

        return detected[i];
      }
    }, 2)
  );

  return { newlyDetected, changed };
}

export { compareDetections };
