import { v4 as uuidv4 } from "uuid";
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

var firebaseConfig = {
  apiKey: "AIzaSyAz_XSvNMaKKH_JVHEKSwilJU59JFErLwM",
  authDomain: "laundryai.firebaseapp.com",
  databaseURL: "https://laundryai.firebaseio.com",
  projectId: "laundryai",
  storageBucket: "laundryai.appspot.com",
  messagingSenderId: "914994085263",
  appId: "1:914994085263:web:86d9431067a0c3366a9ffa",
  measurementId: "G-8XN5WJX80Y",
};

const firebaseApp = initializeApp(firebaseConfig);
const storage = getStorage();

function initializeAnalytics() {
  return getAnalytics();
}

// Get Image / Auth Anon / Upload File    function toggleSignIn() {
async function signIn() {
  const firebaseAuth = getAuth(firebaseApp);
  await signInAnonymously(firebaseAuth).catch((e) => {
    console.log(e.message);
  });
}

function uploadImage(image, progressCallback) {
  const filename = uuidv4() + ".jpeg";
  const imageRef = ref(storage, `/AISchool/uploads/${filename}`);
  const uploadTask = uploadBytesResumable(imageRef, image);
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      progressCallback(Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100));
    },
    (error) => {
      console.error(error);
      alert(error.message);
    }
  );
}

async function submitImage(image, progressTracker) {
  await signIn();
  uploadImage(image, progressTracker);
}

export { submitImage, initializeAnalytics };
