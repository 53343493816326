import * as React from "react";

function SvgBleachNo(props) {
  return (
    <svg viewBox="0 0 10.583 10.583" {...props}>
      <g fill="none" stroke="#000" strokeWidth={0.265}>
        <path d="M.238 10.475L5.194.298l5.174 10.137zM.045 3.419l10.563 5.268M10.603 3.356L.07 8.71" />
      </g>
    </svg>
  );
}

export default SvgBleachNo;
