import * as React from "react";

function SvgDrycleanNo(props) {
  return (
    <svg viewBox="0 0 10.583 10.583" {...props}>
      <g fill="none" stroke="#000">
        <ellipse
          ry={3.798}
          rx={3.697}
          cy={5.562}
          cx={5.124}
          strokeWidth={0.288}
        />
        <path
          d="M.636 1.026l9.07 8.872M9.83.858L.732 9.875"
          strokeWidth={0.265}
        />
      </g>
    </svg>
  );
}

export default SvgDrycleanNo;
