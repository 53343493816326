import * as React from "react";

function SvgIronNo(props) {
  return (
    <svg viewBox="0 0 10.583 10.583" {...props}>
      <g fill="none" stroke="#000">
        <path
          d="M2.028 1.587L8.2 1.52l2.191 6.913-10.328.084"
          strokeWidth={0.263}
        />
        <path
          d="M.063 8.518C.637 7.242 1.21 5.967 2.712 5.3c1.5-.667 3.93-.726 6.358-.784"
          strokeWidth={0.262}
        />
        <path
          d="M.11 2.748l10.394 7.107M10.493 2.529L.063 9.843"
          strokeWidth={0.265}
        />
      </g>
    </svg>
  );
}

export default SvgIronNo;
