import * as React from "react";

function SvgPermpress(props) {
  return (
    <svg viewBox="0 0 10.583 2.646" {...props}>
      <path
        d="M.451 1.336l9.722.012"
        fill="none"
        stroke="#000"
        strokeWidth={0.565}
      />
    </svg>
  );
}

export default SvgPermpress;
