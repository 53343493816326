// Need function that will create a canvas and draw an image on it captured by webcam.
// Then take the canvas and create and return a blob object that can be uploaded.

function captureBlob(canvas) {
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      "image/jpeg",
      1
    );
  });
}

function captureBase64(canvas) {
  const base64 = canvas.toDataURL("image/jpeg", 1);
  return base64;
}

async function captureImage(webcam) {
  const { canvas } = drawOnCanvas(webcam);
  const capturedBlob = await captureBlob(canvas);
  const capturedBase64 = await captureBase64(canvas);

  return { capturedBlob, capturedBase64 };
}

async function getRenderableSize(width, height) {
  let canvas = document.createElement("canvas");
  canvas.width = 640;
  canvas.height = 640;
  const fitted = fitImage(canvas, width, height);

  return fitted;
}

function fitImage(canvas, imageWidth, imageHeight) {
  var imageAspectRatio = imageWidth / imageHeight;
  var canvasAspectRatio = canvas.width / canvas.height;
  var renderableHeight, renderableWidth, xStart, yStart;

  // If image's aspect ratio is less than canvas's we fit on height
  // and place the image centrally along width
  if (imageAspectRatio < canvasAspectRatio) {
    renderableHeight = canvas.height;
    renderableWidth = imageWidth * (renderableHeight / imageHeight);
    xStart = (canvas.width - renderableWidth) / 2;
    yStart = 0;
  }

  // If image's aspect ratio is greater than canvas's we fit on width
  // and place the image centrally along height
  else if (imageAspectRatio > canvasAspectRatio) {
    renderableWidth = canvas.width;
    renderableHeight = imageHeight * (renderableWidth / imageWidth);
    xStart = 0;
    yStart = (canvas.height - renderableHeight) / 2;
  }

  // Happy path - keep aspect ratio
  else {
    renderableHeight = canvas.height;
    renderableWidth = canvas.width;
    xStart = 0;
    yStart = 0;
  }

  return { xStart, yStart, renderableWidth, renderableHeight };
}

function drawOnCanvas(input) {
  const imgSize =
    input.constructor.name === "HTMLVideoElement"
      ? [input.videoHeight, input.videoWidth]
      : [input.height, input.width];
  const canvas = document.createElement("canvas");
  canvas.width = 640;
  canvas.height = 640;
  const ctx = canvas.getContext("2d", { alpha: false });

  const { xStart, yStart, renderableWidth, renderableHeight } = fitImage(
    canvas,
    imgSize[1],
    imgSize[0]
  );

  ctx.drawImage(input, xStart, yStart, renderableWidth, renderableHeight);

  return { canvas, xStart, yStart, renderableWidth, renderableHeight };
}

function* prepImageForSuds(input) {
  const imgSize =
    input.constructor.name === "HTMLVideoElement"
      ? [input.videoHeight, input.videoWidth]
      : [input.height, input.width];
  const canvas = document.createElement("canvas");
  canvas.width = 640;
  canvas.height = 640;

  const ctx = canvas.getContext("2d", { alpha: false });
  yield;
  const { xStart, yStart, renderableWidth, renderableHeight } = fitImage(
    canvas,
    imgSize[1],
    imgSize[0]
  );
  yield;
  ctx.drawImage(input, xStart, yStart, renderableWidth, renderableHeight);
  yield;

  return { image: canvas, xStart, yStart, renderableWidth, renderableHeight };
}

export { captureImage, fitImage, getRenderableSize, prepImageForSuds };
