import * as React from "react";

function SvgDryerNo(props) {
  return (
    <svg viewBox="0 0 10.583 10.583" {...props}>
      <g fill="none" stroke="#000">
        <ellipse
          cx={5.32}
          cy={5.282}
          rx={4.496}
          ry={4.549}
          strokeWidth={0.418}
        />
        <path strokeWidth={0.265} d="M.14.158h10.349v10.31H.14z" />
        <path d="M.14 10.467L10.49.157" strokeWidth={0.273} />
        <path d="M.14.158l10.35 10.31" strokeWidth={0.265} />
      </g>
    </svg>
  );
}

export default SvgDryerNo;
