import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../assets/images/logo_icon-512.png";
import { MenuButton } from "./svg";
import { useTranslation } from "react-i18next";

export const LargeHeader = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="header">
      <div className="flex flex-row pt-4">
        <div className="basis-1/4">
          <a href="/">
            <span className="sr-only">{t("header.home")}</span>
            <img className="object-contain h-48" src={logo} alt="Suds logo" />
          </a>
        </div>
        <div className="basis-3/4">
          <h1 className="font-computer">{t("navigation.title")}</h1>
          <p>{t("header.tagLine1")}</p>
          <p className="font-bold text-2xl mt-2">{t("header.tagLine2")}</p>
        </div>
      </div>
      <div className="container">
        {i18n.language === "fr" ? (
          <button
            className=" m-1 pt-1 float-right border-2 rounded-md border-black px-2 hover:text-white hover:border-white"
            onClick={() => {
              changeLanguage("en");
            }}
          >
            English
          </button>
        ) : (
          <button
            className="m-1 pt-1 float-right border-2 rounded-md border-black px-2 py-1 hover:text-white hover:border-white"
            onClick={() => {
              changeLanguage("fr");
            }}
          >
            Français
          </button>
        )}
      </div>
    </div>
  );
};

export const MobileHeader = () => {
  const { t, i18n } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const currentUrl = useLocation();

  useEffect(() => {
    setExpanded(false);
  }, [currentUrl]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setExpanded(false);
  };

  return (
    <div className="header p-2">
      <div className="flex flex-row">
        <div className="basis-3/4">
          <a href="/">
            <span className="sr-only">{t("header.home")}</span>
            <div className="flex flex-row gap-4">
              <div>
                <img className="object-contain h-10" src={logo} alt="Suds logo" />
              </div>
              <div>
                <h1 className="font-computer ">Suds</h1>
              </div>
            </div>
          </a>
        </div>
        <div className="basis-1/4">
          <button className="float-right " onClick={() => setExpanded((curr) => !curr)}>
            <MenuButton className="p-1 border-2 rounded-md border-white h-8 w-auto" />
          </button>
        </div>
      </div>
      {expanded && (
        <div className="flex flex-row">
          <div className="basis-full mt-8">
            <ul className="flex flex-col gap-y-6 text-xl">
              <li>
                <NavLink
                  activeClassName=" border-y-2 rounded-md border-white"
                  className="text-white w-full inline-block py-2"
                  to={"/about"}
                >
                  {t("navigation.aboutUs")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName=" border-y-2 rounded-md border-white"
                  className="text-white w-full inline-block py-2"
                  to={"/privacy"}
                >
                  {t("navigation.privacy")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName=" border-y-2 rounded-md border-white"
                  className="text-white w-full inline-block py-2"
                  to={"/terms_and_conditions"}
                >
                  {t("navigation.terms")}
                </NavLink>
              </li>
              <li>
                {i18n.language === "fr" ? (
                  <button className="text-white italic" onClick={() => changeLanguage("en")}>
                    English
                  </button>
                ) : (
                  <button className="text-white italic" onClick={() => changeLanguage("fr")}>
                    Français
                  </button>
                )}
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
