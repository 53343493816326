import React, { lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { LargeHeader, MobileHeader } from "./components/header";
import Notification from "./components/notification";
import Footer from "./components/Footer/footer";
import { useUpdateAvailable } from "./hooks/useUpdate";
import { useTranslation } from "react-i18next";
import { initializeAnalytics } from "./assets/js/AISchool";
import { useMobile } from "./hooks";

const Home = lazy(() => import("./containers/Home"));
const Detection = lazy(() => import("./containers/Detection"));
const About = lazy(() => import("./containers/About"));
const ToS = lazy(() => import("./containers/ToS"));
const Privacy = lazy(() => import("./containers/Privacy"));
const Tutorial = lazy(() => import("./containers/Tutorial"));
const Feedback = lazy(() => import("./containers/Feedback"));
const AISchool = lazy(() => import("./components/AISchool/reviewImagePage"));
const Results = lazy(() => import("./components/Detection/results"));
const Detector = lazy(() => import("./components/Detection/detector"));

const App = () => {
  if (process.env.NODE_ENV === "production" && navigator.userAgent !== "ReactSnap") {
    initializeAnalytics();
  }

  const { t } = useTranslation();
  const { updateAvailable, updateServiceWorker } = useUpdateAvailable();
  const isMobile = useMobile();

  return (
    <BrowserRouter>
      <Helmet>
        <meta property="og:title" content={t("navigation.title")} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://suds.ai" />
        <meta name="og:description" content={t("landingPage.meta-description")} />
        <meta name="description" content={t("landingPage.meta-description")} />
        <meta property="og:image" content="https://suds.ai/Suds_Share_card.png" />
        <meta name="twitter:card" content="https://suds.ai/Suds_Share_card.png" />
        <meta property="twitter:domain" content="suds.ai" />
        <meta property="twitter:url" content="https://suds.ai" />

        <meta name="twitter:description" content={t("landingPage.meta-description")} />
        <meta name="twitter:image" content="https://suds.ai/Suds_Share_card.png" />
      </Helmet>

      {isMobile ? <MobileHeader /> : <LargeHeader />}
      {updateAvailable && (
        <Notification
          title={t("notification.version-title")}
          messageText={t("notification.version-content")}
          buttonText={t("notification.version-button")}
          onClick={updateServiceWorker}
        />
      )}

      <Routes>
        <Route path="detection" element={<Detection />}>
          <Route path="ai_school" element={<AISchool />} />
          <Route path="results" element={<Results />} />
          <Route path="/detection/" element={<Detector />} />
        </Route>
        <Route path="tutorial" element={<Tutorial />} />
        <Route path="about" element={<About />} />
        <Route path="terms_and_conditions" element={<ToS />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="feedback" element={<Feedback />} />
        <Route path="/" element={<Home />} />
      </Routes>

      <Footer />
    </BrowserRouter>
  );
};

export default App;
