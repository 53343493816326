import * as React from "react";

function SvgDelicate(props) {
  return (
    <svg viewBox="0 0 10.583 2.646" {...props}>
      <g fill="none" stroke="#000" strokeWidth={0.565}>
        <path d="M.493.74l9.722.011M.507 1.715l9.722.012" />
      </g>
    </svg>
  );
}

export default SvgDelicate;
