import * as React from "react";

function SvgLinedry(props) {
  return (
    <svg viewBox="0 0 10.583 10.583" {...props}>
      <g fill="none" strokeWidth={0.265} stroke="#000">
        <path d="M.153.182h10.291v10.261H.153zM1.275 1.34l.017 8.564M9.267 1.285l.027 8.606" />
        <path d="M1.275 1.34c1.276.81 2.55 1.621 3.883 1.612 1.332-.01 2.72-.838 4.109-1.667" />
      </g>
    </svg>
  );
}

export default SvgLinedry;
