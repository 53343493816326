import { useState, useEffect } from "react";

export const useCamera = () => {
  const [stream, setStream] = useState(null);
  const [zoomSettings, setZoomSettings] = useState({});

  useEffect(() => {
    const captureSettings = {
      audio: false,
      video: {
        facingMode: "environment",
        width: { ideal: 1280 },
        height: { ideal: 720 },
      },
    };
    async function enableStream() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(captureSettings);
        setStream(stream);
      } catch (err) {
        // Removed for brevity
      }
    }
    if (!stream) {
      enableStream();
    } else {
      const track = stream.getVideoTracks()[0];
      const trackSettings = track.getSettings();

      // Check whether zoom is supported or not.
      if (trackSettings.zoom) {
        // Map zoom to a slider element.
        const trackCapabilities = track.getCapabilities();
        setZoomSettings({
          min: trackCapabilities.zoom.min,
          max: trackCapabilities.zoom.max / 2,
          step: trackCapabilities.zoom.step,
          current: trackSettings.zoom,
          constraints: (event) => {
            track.applyConstraints({
              advanced: [{ zoom: event.target.value }],
            });
          },
        });
      }
    }
    console.debug("stream", stream);

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [stream]);

  return { stream, zoomSettings };
};

export default useCamera;
