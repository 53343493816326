import { useEffect, useState, useRef } from "react";
import { prepImageForSuds } from "../assets/js/cameraUtil";
import { detectAndBox } from "../assets/js/yolo";
import { compareDetections } from "../assets/js/detectLogic";
import { run } from "js-coroutines";
import { useOutletContext } from "react-router-dom";

export const useInference = (videoElement, model, active) => {
  const _detected = useRef([[{}, {}], {}, [{}, {}], {}, {}, {}]);

  const [inferenceData, setInferenceData] = useState({
    detected: [[{}, {}], {}, [{}, {}], {}, {}, {}],
    imageData: {},
    frameFeatures: {},
  });

  const { updateDetected } = useOutletContext();

  useEffect(() => {
    const inferenceProcess = run(function* () {
      console.log("Inference process started..");
      if (videoElement && model && active) {
        do {
          const { image, xStart, yStart, renderableWidth, renderableHeight } =
            yield* prepImageForSuds(videoElement);

          const features = yield detectAndBox(image, model);

          if (Array.isArray(features)) {
            const { newlyDetected, changed } = yield* compareDetections(
              _detected.current,
              features
            );

            console.log("Newly detected: ", JSON.stringify(newlyDetected));
            console.log("Changed: ", changed);
            console.log("Detected: ", JSON.stringify(_detected.current));

            if (changed) {
              updateDetected(newlyDetected);
              _detected.current = newlyDetected;
            }

            setInferenceData({
              detected: newlyDetected,
              imageData: { xStart, yStart, renderableWidth, renderableHeight },
              frameFeatures: features,
            });
          }
        } while (active);
      }
    });
    return () => {
      console.log("Inference process terminated..");
      inferenceProcess.terminate();
    };
  }, [videoElement, model, active, updateDetected]);

  return inferenceData;
};

export default useInference;
